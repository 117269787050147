var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 },"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'title',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'title',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("分类")]),_c('a-radio-group',{model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}},_vm._l((_vm.typeList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),(_vm.category === 'bid_news')?_c('a-form-item',{attrs:{"label":"作者"}},[_c('a-input',{model:{value:(_vm.author),callback:function ($$v) {_vm.author=$$v},expression:"author"}})],1):_vm._e(),(_vm.category === 'bid_project')?_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("项目")]),_c('BidSelector',{on:{"change":(company) => (_vm.selectedBid = company)}},[(_vm.selectedBid.name)?_c('div',{staticClass:"selected"},[_c('span',[_vm._v(" "+_vm._s(_vm.selectedBid.name)+" ")]),_c('a-button',{staticStyle:{"margin-left":"5px"}},[_vm._v("修改")])],1):_c('a-button',[_vm._v("请选择项目")])],1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"是否展示"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'isShow',
            {
              rules: [
                {
                  required: true,
                  message: '请选择！',
                },
              ],
            },
          ]),expression:"[\n            'isShow',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请选择！',\n                },\n              ],\n            },\n          ]"}]},[_c('a-radio',{attrs:{"value":1}},[_vm._v("是")]),_c('a-radio',{attrs:{"value":0}},[_vm._v("否")])],1)],1),_c('a-form-item',{attrs:{"label":"置顶值"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['top']),expression:"['top']"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"置顶值越高，排名越靠前"}})],1),_c('a-form-item',{attrs:{"label":"封面图"}},[_c('FileUpload',{attrs:{"uploadType":"image","multiple":false,"imagePath":_vm.imagePath},on:{"uploaded":_vm.imgUploaded}})],1),_c('a-form-item',{attrs:{"label":"发布时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'publishAt',
            {
              initialValue: _vm.moment(),
              rules: [{ required: true, message: '请选择！' }],
            },
          ]),expression:"[\n            'publishAt',\n            {\n              initialValue: moment(),\n              rules: [{ required: true, message: '请选择！' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"","format":"YYYY-MM-DD HH:mm:ss"}})],1),_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("正文")]),_c('div',{attrs:{"id":"editor"}})]),_c('a-form-item',{staticClass:"file-container",attrs:{"label":"附件"}},[_c('div',{staticClass:"link-list"},[(_vm.fileList.length !== 0)?_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0):_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v("请上传附件")]),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)]),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.saveLoading}},[_vm._v("修改")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }