<template>
  <div>
    <div @click="visible = true">
      <slot />
    </div>

    <a-modal
      title="选择项目"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
      width="800px"
    >
      <a-input-search
        placeholder="请输入项目名搜索"
        enter-button
        v-model="searchText"
        @search="onSearch"
      ></a-input-search>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        :rowClassName="() => 'clickable'"
        :customRow="
          (record) => {
            return {
              on: {
                click: () => {
                  onTableRowClick(record);
                },
              },
            };
          }
        "
      >
        <a-table-column title="编号" data-index="id" />
        <a-table-column title="名称" data-index="name" />
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { fetchList } from "@/api/bidding";
export default {
  data() {
    return {
      visible: false,
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,
      
      searchText:'',
    };
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        if (this.list.length === 0) {
          this.getList();
        }
      }
    },
  },

  mounted(){
    this.getList()
  },

  methods: {
    getList(){
      this.loading = true;
      let {current,pageSize,searchText} = this
      
      fetchList({
        pageNum: current,
        pageSize,
        company: searchText
        })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list
            this.total = res.totalSize
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    onSearch(value) {
      this.searchText = value
      this.current = 1;
      this.getList();
    },

    onTableRowClick(record) {
      this.$emit("change", record);
      this.visible = false;
    },

    cancel() {
      this.visible = false;
    },
  },
};
</script>
