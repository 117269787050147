<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        @submit="handleSubmit"
        :form="form"
      >
        <a-form-item label="标题">
          <a-input
            v-decorator="[
              'title',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item>
          <span class="required" slot="label">分类</span>
          <a-radio-group v-model="category">
            <a-radio
              v-for="item in typeList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-radio
            >
          </a-radio-group>
        </a-form-item>

        <a-form-item label="作者" v-if="category === 'bid_news'">
          <a-input v-model="author" />
        </a-form-item>

        <a-form-item v-if="category === 'bid_project'">
          <span class="required" slot="label">项目</span>
          <BidSelector @change="(company) => (selectedBid = company)">
            <div v-if="selectedBid.name" class="selected">
              <span>
                {{ selectedBid.name }}
              </span>
              <a-button style="margin-left: 5px">修改</a-button>
            </div>
            <a-button v-else>请选择项目</a-button>
          </BidSelector>
        </a-form-item>

        <a-form-item label="是否展示">
          <a-radio-group
            v-decorator="[
              'isShow',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择！',
                  },
                ],
              },
            ]"
          >
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="置顶值">
          <a-input-number
            placeholder="置顶值越高，排名越靠前"
            style="width: 100%"
            v-decorator="['top']"
          />
        </a-form-item>

        <a-form-item label="封面图">
          <FileUpload
            uploadType="image"
            :multiple="false"
            @uploaded="imgUploaded"
            :imagePath="imagePath"
          />
        </a-form-item>

        <a-form-item label="发布时间">
          <a-date-picker
            placeholder=""
            v-decorator="[
              'publishAt',
              {
                initialValue: moment(),
                rules: [{ required: true, message: '请选择！' }],
              },
            ]"
            style="width: 100%"
            format="YYYY-MM-DD HH:mm:ss"
          />
        </a-form-item>

        <a-form-item>
          <span class="required" slot="label">正文</span>
          <div id="editor"></div>
        </a-form-item>

        <a-form-item label="附件" class="file-container">
          <div class="link-list">
            <a-checkbox-group
              :value="selectedFileList"
              @change="onSelectFile"
              v-if="fileList.length !== 0"
            >
              <div class="link" v-for="item in fileList" :key="item.id">
                <a-checkbox :value="item.id">
                  <a target="_blank" download :href="item.completePath">{{
                    item.name
                  }}</a>
                </a-checkbox>
              </div>
            </a-checkbox-group>

            <div style="color: #ccc" v-else>请上传附件</div>

            <div class="control-bar">
              <FileUpload @uploaded="uploaded">
                <div class="control">
                  <a-icon type="upload" />
                </div>
              </FileUpload>

              <div class="control" @click="deleteFile">
                <a-icon type="delete" />
              </div>
            </div>
          </div>
        </a-form-item>

        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button htmlType="submit" type="primary" :loading="saveLoading"
              >修改</a-button
            >
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import E from "wangeditor";
import FileUpload from "@/components/file-upload";
import BidSelector from "./components/bid-selector";

import moment from "moment";
import { fetchDetail, edit } from "@/api/bidding/article";
import { mapGetters } from "vuex";

export default {
  name: "editBiddingArticle",

  components: {
    FileUpload,
    BidSelector,
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("bid_article_cate").filter(
        (item) => item.value !== "bid_win"
      );
    },
  },

  data() {
    return {
      id: "",
      form: this.$form.createForm(this),
      category: "",
      author: "",
      selectedBid: "",
      content: "",

      editor: null,
      imagePath: "",

      fileList: [],
      selectedFileList: [],

      saveLoading: false,
    };
  },

  mounted() {
    const editor = new E("#editor");
    const that = this;

    editor.config.height = 600;
    editor.config.zIndex = 1;
    editor.config.placeholder = "";
    editor.config.uploadImgServer = "/api/common-service/file/upload";
    editor.config.uploadImgHeaders = {
      token: window.localStorage.getItem("token"),
    };
    editor.config.uploadFileName = "files";
    editor.config.uploadImgHooks = {
      customInsert: function (insertImgFn, result) {
        if (result && Array.isArray(result.data) && result.data.length > 0) {
          insertImgFn(result.data[0].completePath);
        }
      },
    };

    editor.config.onchange = function () {
      that.content = editor.txt.text();
    };
    editor.config.onchangeTimeout = 500; // 修改为 500ms

    editor.create();
    this.editor = editor;

    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      this.getDetail();
    }
  },

  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  },

  methods: {
    getDetail() {
      fetchDetail(this.id).then((res) => {
        console.log(res);
        this.imagePath = res.face;
        this.selectedBid = { name: res.itemName };

        if (this.editor) {
          this.editor.txt.html(res.content); // 重新设置编辑器内容
        }
        this.fileList = JSON.parse(res.attachments) || [];
        this.author = res.author;
        this.category = res.category;
        this.imagePath = res.face;

        this.form.setFieldsValue({
          title: res.title,
          top: res.top,
          publishAt: res.publishAt
            ? moment(res.publishAt)
            : moment(res.createAt),
          isShow: res.isShow,
        });
      });
    },
    moment() {
      return moment();
    },

    imgUploaded(res) {
      if (res.length > 0) {
        this.imagePath = res[0].completePath;
      }
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },

    handleSubmit(e) {
      e.preventDefault();
      const content = this.editor.txt.html();

      if (content.length === 0) {
        this.$message.error("请输入正文！");
        return;
      }

      if (!this.imagePath) {
        this.$message.error("请上传封面图");
        return;
      }

      if (!this.category) {
        this.$message.error("请选择分类");
        return;
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;
          console.log(values);
          console.log(content);
          console.log(this.imagePath);
          console.log(this.category);
          console.log(this.imagePath);
          let params = {};

          if (this.category === "bid_news") {
            params = {
              id: this.id,
              ...values,
              author: this.author,
              content,
              face: this.imagePath,
              category: this.category,
              attachments: JSON.stringify(this.fileList),
              publishAt: values.publishAt.format("YYYY-MM-DD HH:mm:ss"),
            };
          } else {
            params = {
              id: this.id,
              ...values,
              itemId: this.selectedBid.id,
              content,
              face: this.imagePath,
              category: this.category,
              attachments: JSON.stringify(this.fileList),
              publishAt: values.publishAt.format("YYYY-MM-DD HH:mm:ss"),
            };
          }

          console.log(params);

          edit(params)
            .then(() => {})
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 30px 50px;
}

.center {
  margin-top: 64px;
}
</style>
